




























import Vue from 'vue';
import BasicInfo from './basicInfo.vue';
import detailTable from './detailTable.vue';
import { schemes } from '@/api/costQuotaLibrary/detail';
import AddInventoryProject from './addInventoryProject.vue';
import { set } from 'nprogress';
import { checkUserIdentity } from '../index';
export default Vue.extend({
  name: 'detail',
  components: { AddInventoryProject, BasicInfo, detailTable },
  props: {},
  data() {
    return {
      currentCostInfo: {} as any,
      tableData: [],
      addVisible: false,
      currentInventoryList: [] as any[], // 当前清单的基本数据
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  mounted() {
    console.log('index.vue|21====>', '');
    const currentCostInfo = sessionStorage.getItem('currentCostInfo') as string;
    this.currentCostInfo = JSON.parse(currentCostInfo);
    this.schemes();
  },
  methods: {
    // 获取详情页表格数据
    schemes() {
      let apiData = {
        majorNbr: this.currentCostInfo.majorNbr,
        bdCode: this.currentCostInfo.bdCode,
        bdName: this.currentCostInfo.bdName,
        unit: this.currentCostInfo.unit,
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      };
      schemes(apiData).then((res: any) => {
        if (res.status === 200 && res.result) {
          this.tableData = res.result.list;
          this.pagination.total = res.result.total;
          this.currentCostInfo.planQuantity = res.result.total;
        }
      });
    },
    init() {
      this.pagination = {
        ...this.pagination,
        current: 1,
        pageSize: 10,
      };
      this.schemes();
    },
    openAdd() {
      if (!checkUserIdentity(this)) return;
      const obj = {
        ...this.currentCostInfo,
        projectAttr: '',
        price: '',
      };
      this.addVisible = true;
      this.$nextTick(() => {
        this.currentInventoryList = [obj];
      });
    },
    paginationChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination = {
        ...pagination,
        current,
        pageSize,
      };
      this.schemes();
    },
  },
});
