<!--
 * @Descripttion: 组价清单信息模块
 * @Author: liuxia
 * @Date: 2022-06-10 10:16:29
 * @LastEditors: renmingming
 * @LastEditTime: 2022-08-19 10:58:50
-->
<template>
  <div class="basic-info">
    <div class="left-info">
      <h3>清单信息</h3>
      <div class="content">
        <div class="info-group">
          <div class="single-unit" v-for="(item, index) in info" :key="index">
            <span>{{ item.value }}</span>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <a-divider type="vertical" />
    <div class="btn-group">
      <a-button @click="openChart">综合单价趋势图</a-button>
    </div>
    <priceTrendChart
      :visible.sync="chartVisible"
      :basicInfo="basicInfo"
    ></priceTrendChart>
  </div>
</template>

<script>
import Vue from 'vue';
import priceTrendChart from './priceTrendChart.vue';
import { checkUserIdentity } from '../index';
export default Vue.extend({
  name: 'basicInfo',
  props: {
    basicInfo: {
      type: Object,
    },
  },
  data() {
    return {
      info: [
        {
          name: '清单编码',
          value: '',
        },
        {
          name: '项目名称',
          value: '',
        },
        {
          name: '单位',
          value: '',
        },
        {
          name: '方案数',
          value: '',
        },
      ],
      addVisible: false,
      chartVisible: false,
    };
  },
  watch: {
    basicInfo: function (val) {
      if (val) {
        this.info[0].value = this.basicInfo.bdCode;
        this.info[1].value = this.basicInfo.bdName;
        this.info[2].value = this.basicInfo.unit;
        this.info[3].value = this.basicInfo.planQuantity;
      }
    },
    'basicInfo.planQuantity': function (val) {
      if (val) {
        this.info[3].value = val;
      }
    },
  },
  methods: {
    add() {
      this.addVisible = true;
    },
    openChart() {
      if (!checkUserIdentity(this)) return;
      this.chartVisible = true;
    },
  },
  components: {
    priceTrendChart,
  },
});
</script>

<style lang="less" scoped>
.basic-info {
  background: #247fff url('../../../../assets/img/detail-bg.png') no-repeat;
  width: 100%;
  height: 140px;
  padding: 30px 0 20px 46px;
  color: #ffffff;
  display: flex;
  align-items: center;
  .left-info {
    width: 75%;
  }
  h3 {
    color: #ffffff;
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      left: -22px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      content: '';
      display: block;
      width: 2px;
      height: 11px;
      background: #ffffff;
    }
  }
  .content {
    display: flex;
    align-items: center;
    .info-group {
      display: flex;
      width: 90%;
      justify-content: space-between;
      .single-unit {
        span {
          display: block;
        }
        span:nth-of-type(1) {
          font-size: 28px;
        }
        span:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 5.9em;
  }
  .btn-group {
    flex: 1;
    text-align: center;
    button + button {
      margin-left: 15px;
    }
    .ant-btn {
      width: 173px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 2px;
      border: 1px solid #ffffff;
      background-color: transparent;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
